"use client";
import SettingsProvider from "@wt/game/providers/settings/provider";
import ToastifyProvider from "@wt/game/providers/toasts";
import { ThemeProvider } from "next-themes";
import { ReactNode } from "react";

import { BannerAdOpenProvider } from "../bannerAdOpen/BannerAdOpenProvider";
import { RoundProgressProvider } from "../roundProgress/RoundProgressProvider";
import { UserProvider } from "../user/UserProvider";
import { MuiThemeProvider } from "./MuiThemeProvider";
import { TooltipsProvider } from '../tooltips';
import { RewardedAdsProvider } from '../ads/RewardedAdsProvider';


export const Providers = ({ children }: { children: ReactNode }) => {
  return (
    <RewardedAdsProvider>
      <ThemeProvider defaultTheme="dark" enableSystem={false}>
        <MuiThemeProvider>
          <UserProvider>
            <SettingsProvider>
              <RoundProgressProvider>
                <BannerAdOpenProvider>
                  <TooltipsProvider>{children}</TooltipsProvider>
                </BannerAdOpenProvider>
              </RoundProgressProvider>
            </SettingsProvider>
            <ToastifyProvider />
          </UserProvider>
        </MuiThemeProvider>
      </ThemeProvider>
    </RewardedAdsProvider>
  );
};
