"use client";
import {
  defaultSettings,
  SettingsContext,
  SettingsContextProps,
  SettingsStateProps,
} from "@wt/game/providers/settings/types";
import useLocalStorageState from "@wt/shared/hooks/useLocalStorageState";
import { ReactNode, useEffect, useState } from "react";

const SettingsProvider = ({ children }: { children: ReactNode }) => {
  const [settings, setSettings] = useLocalStorageState<SettingsStateProps>(
    "settings",
    {
      defaultValue: defaultSettings,
    },
  );

  const [loaded, setLoaded] = useState<boolean>(false);

  const updateSettings = (newData: Partial<SettingsStateProps>) => {
    const currentSettings = settings ? settings : defaultSettings;
    const newSettings = {
      ...currentSettings,
      ...newData,
    };
    setSettings(newSettings);
  };

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
    }
  }, []);

  const data: SettingsContextProps = [
    settings ? { ...settings } : { ...defaultSettings },
    updateSettings,
  ];

  return (
    <SettingsContext.Provider value={data}>{children}</SettingsContext.Provider>
  );
};

export default SettingsProvider;
