'use client';

import { nullFunc } from '@wt/utilities/nullFunction';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

interface RewardedAdsContextType {
  runRewardedAd: (onGrant: () => void) => void;
}

const initialContextValue: RewardedAdsContextType = {
  runRewardedAd: nullFunc,
};

const RewardedAdsContext =
  createContext<RewardedAdsContextType>(initialContextValue);

export const useRewardedAds = () => useContext(RewardedAdsContext);

export const RewardedAdsProvider = ({ children }: { children: ReactNode }) => {
  const [onGrant, setOnGrant] = useState<() => void>();
  const [makeVisible, setMakeVisible] = useState<() => void>();

  const addNewSlot = useCallback((refresh: boolean) => {
    const rewardedSlot = googletag
      .defineOutOfPageSlot(
        '/22152718,22828237697/Whentaken_RewardedAds_web',
        googletag.enums.OutOfPageFormat.REWARDED
      )
      ?.addService(googletag.pubads());
    if (!rewardedSlot) return rewardedSlot;

    rewardedSlot.setForceSafeFrame(true);
    googletag.enableServices();

    if (refresh) {
      googletag.pubads().refresh([rewardedSlot]);
    }

    googletag.display(rewardedSlot);
    return rewardedSlot;
  }, []);

  useEffect(() => {
    window.googletag = window.googletag || { cmd: [] };
    window.googletag.cmd.push(() => {
      googletag.pubads().set('page_url', 'whentaken.com');

      addNewSlot(false);

      googletag
        .pubads()
        .addEventListener(
          'rewardedSlotReady',
          (evt: googletag.events.RewardedSlotReadyEvent) => {
            // The ad is ready to be shown to the user
            setMakeVisible(() => evt.makeRewardedVisible);
          }
        );
      googletag
        .pubads()
        .addEventListener(
          'rewardedSlotClosed',
          (evt: googletag.events.RewardedSlotClosedEvent) => {
            // The user closed the ad (prematurely or otherwise)
            // Destroy the slot
            googletag.destroySlots([evt.slot]);
            setMakeVisible(undefined);
            // Initialise a new slot
            addNewSlot(true);
          }
        );
    });
  }, []);

  useEffect(() => {
    const onRewardedSlotGranted = (
      evt: googletag.events.RewardedSlotGrantedEvent
    ) => {
      // The user has watched the ad and should be granted with the reward
      // evt.payload == { amount: number, type: string }
      if (onGrant) {
        onGrant();
      }
    };

    // Initialise RewardedAds
    window.googletag.cmd.push(() => {
      googletag
        .pubads()
        .addEventListener('rewardedSlotGranted', onRewardedSlotGranted);
    });

    return () => {
      googletag.cmd.push(() => {
        googletag
          .pubads()
          .removeEventListener('rewardedSlotGranted', onRewardedSlotGranted);
      });
    };
  }, [onGrant]);

  const runRewardedAd = (onGrant: () => void) => {
    setOnGrant(() => onGrant);
    if (makeVisible) {
      googletag.cmd.push(() => {
        makeVisible();
      });
    } else {
      // Ad is not ready, let's just grant the user
      onGrant();
    }
  };

  return (
    <RewardedAdsContext.Provider value={{ runRewardedAd }}>
      {children}
    </RewardedAdsContext.Provider>
  );
};
