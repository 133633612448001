"use client";

import { useUserProvider } from "@wt/game/providers/user/UserProvider";
import { destroyVideoAd, refreshVideoAd } from "@wt/shared/hooks/useAds/useAds";
import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";

// Desktop only
export const VideoAd = () => {
  const [showingVideo, setShowingVideo] = useState<boolean>(true);
  const pathname = usePathname();
  const { user } = useUserProvider();

  useEffect(() => {
    if (pathname === "/community" && showingVideo) {
      setShowingVideo(false);
      destroyVideoAd();
    } else if (pathname !== "/community" && !showingVideo) {
      setShowingVideo(true);
      refreshVideoAd();
    }
  }, [pathname]);

  if (pathname.includes('admin') || pathname === '/community' || user.isPremium) {
    return <></>;
  }

  return (
    <div className="hidden lg:flex">
      <div id="adngin-video-0"></div>
    </div>
  );
};
