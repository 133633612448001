'use client';

import { setCookie } from '@wt/utilities/cookies';
import { useEffect } from 'react';

export default () => {
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    if (urlParams.has('token') && urlParams.has('refreshToken')) {
      // Logging in...
      setCookie('jwt', urlParams.get('token') ?? '', 100);
      setCookie('refreshToken', urlParams.get('refreshToken') ?? '', 100);
      setCookie('isLoggedIn', 'true', 100);

      const now = new Date();
      const utcString = now.toUTCString();
      setCookie('refreshDate', utcString, 100);

      urlParams.delete('token');
      urlParams.delete('refreshToken');

      const newQueryString =
        urlParams.toString().length > 0 ? '?' + urlParams.toString() : '';
      const newUrl =
        window.location.protocol +
        '//' +
        window.location.host +
        window.location.pathname +
        newQueryString +
        window.location.hash;
      history.replaceState(null, '', newUrl);
    }
  });

  return null;
};
