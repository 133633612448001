'use client';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { inIFrame } from '@wt/utilities/inIFrame';
import { getSiteName, getSiteURL } from '@wt/utilities/siteMode';
import Link from 'next/link';
import { useEffect, useState } from 'react';

function IFrameWarningModal() {
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (inIFrame()) {
      setOpen(true);
      window.gtag && window.gtag('event', 'iframe_popup');
    }
  }, []);

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      sx={{
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        '&.MuiBox-root': {
          border: 'none',
        },
        '&:focus': {
          outline: 'none',
        },
      }}
    >
      <Box
        sx={{
          '&:focus': {
            outline: 'none',
          },
        }}
      >
        <IFrameWarningContent handleClose={() => setOpen(false)} />
      </Box>
    </Modal>
  );
}

export default IFrameWarningModal;

const IFrameWarningContent = (props: { handleClose: () => void }) => {
  return (
    <div className="m-4 flex min-w-[300px] max-w-[500px] flex-col space-y-3 rounded-3xl bg-base-200 p-6 md:min-w-[350px]">
      <div className="flex w-full flex-row items-center">
        <div className="grow text-center">
          <span className="text-xl font-medium lg:text-4xl">Error!</span>
        </div>
        <Link href={'#'} onClick={props.handleClose}>
          <CloseRoundedIcon className="m-0 fill-current text-2xl leading-none" />
        </Link>
      </div>
      <div>
        {`${getSiteName()}'s official website is ${getSiteURL()} It looks like you're
        playing on a website that has stolen it!`}
      </div>
      <div>
        {`These websites steal revenue from game creators by re-hosting their
        games and overlaying their own ads. Play ${getSiteName()} on ${getSiteURL()} , and you'll
        always have the latest version, fewer ads and help support the creators
        (i.e. us)!`}
      </div>
      <div>Thanks,</div>
      <div>- Teuteuf Team</div>
      <button
        type="button"
        onClick={() => window.open(`${getSiteURL()}?utm_campaign=iframe_popup`)}
        className="btn btn-primary h-[33px] min-h-[33px] shrink px-5 text-sm font-normal text-white md:h-[40px] md:min-h-[40px] md:px-8 md:text-base"
      >
        Go There
      </button>
    </div>
  );
};
