import { ThemeProvider, createTheme } from '@mui/material';
import { getSiteThemes } from '@wt/utilities/siteMode';
import { useTheme } from 'next-themes';
import { ReactNode } from 'react';

export const MuiThemeProvider = (props: { children: ReactNode }) => {
  const { theme } = useTheme();
  const { lightTheme, darkTheme } = getSiteThemes();
  const muiTheme = createTheme({
    palette: {
      mode: theme == 'dark' ? 'dark' : 'light',
      primary: {
        main: theme == 'dark' ? darkTheme.primary : lightTheme.primary,
      },
    },
  });
  return (
    <>
      <ThemeProvider theme={muiTheme}>{props.children}</ThemeProvider>
    </>
  );
};
