'use client'

import { redirect } from 'next/navigation';
import React, {Component, ErrorInfo} from "react";

interface Props {
    children: React.ReactNode;
}

interface State {
    hasError: boolean;
}

export default class AppErrorBoundary extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error('ErrorBoundary caught an error: ', error, errorInfo);
        this.setState({ hasError: true });
    }

    render() {
        if (this.state.hasError) {
            return (
              <section className="flex size-full flex-col items-center justify-center">
                <div className="mx-auto max-w-screen-xl px-4 py-8 lg:px-6 lg:py-16">
                  <div className="mx-auto max-w-screen-sm text-center">
                    <>
                      <h1 className="mb-4 text-7xl font-extrabold tracking-tight text-accent lg:text-9xl">500</h1>
                      <p className="mb-4 text-3xl font-bold tracking-tight  md:text-4xl ">Something went wrong</p>
                      <p className="mb-4 text-lg font-light text-base-content/90">Please try again. If the problem persists, try clearing your browser cache and hard refreshing the page.</p>
                    </>
                  </div>
                  <div className="flex w-full justify-center">
                    <div
                      onClick={() => {
                        this.setState({ hasError: false })
                        redirect('/');
                      }}
                      className="flex h-12 cursor-pointer items-center justify-center gap-2.5 rounded-lg border-2 bg-white px-4 py-2.5 md:h-14 md:rounded-xl md:px-6 md:py-4"
                    >
                      <div className="whitespace-nowrap text-base font-bold uppercase text-slate-900 sm:text-xl  md:text-xl">
                          Try Again
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            );
        }

        return this.props.children;
    }
}